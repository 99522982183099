import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, useLocation, useParams } from "react-router";
import { useTranslation } from "react-i18next";

import { useGetCategories } from "./useGetCategories";
import { useGetProducts } from "./useGetProducts";
import Container from "../../shared/wrappers/Container";
import { LeadTextAccent } from "../../shared/components/elements";
import Sort from "./components/Sort";
import Categories from "./components/Categories";
import Products from "./components/Products";
import { sortOptions } from "../../utils/constants";

const Catalog = () => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { t } = useTranslation();

  const [currentId, setCurrentId] = useState(null);
  const [sortBy, setSortBy] = useState(sortOptions[0]);
  const categories = useGetCategories();
  const products = useGetProducts(currentId);

  useEffect(() => {
    if (params.categoryId) {
      setCurrentId(params.categoryId);
    } else if (categories.length) {
      history.push(`${location.pathname}/${categories[0].id}`);
    }
  }, [categories, params]);

  return (
    <Wrapper>
      <Container>
        <GridHolder>
          <TitleArea>
            <LeadTextAccent>{t("common:Catalog")}</LeadTextAccent>
          </TitleArea>

          <SortArea>
            <Sort sortBy={sortBy} setSortBy={setSortBy} />
          </SortArea>

          <CategoriesArea>
            <Categories data={categories} currentId={currentId} />
          </CategoriesArea>

          <CatalogArea>
            {products.length ? (
              <Products
                category={categories.find(
                  (category) => category.id === currentId
                )}
                products={products}
                sortBy={sortBy}
              />
            ) : (
              "Loading"
            )}
          </CatalogArea>
        </GridHolder>
      </Container>
    </Wrapper>
  );
};

export default Catalog;

const Wrapper = styled.div`
  padding-top: 96px;
  padding-bottom: 24px;
  background-color: ${({ theme }) => theme.colors.beige};
`;

const GridHolder = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas:
    "title title title sort"
    "categories catalog catalog catalog";
  grid-gap: 24px 32px;

  @media screen and (max-width: 425px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "title title"
      "categories sort"
      "catalog catalog";
    grid-gap: 16px 8px;
  }
`;

const TitleArea = styled.div`
  grid-area: title;
`;

const SortArea = styled.div`
  grid-area: sort;
  align-self: center;
  justify-self: end;

  @media screen and (max-width: 425px) {
    justify-self: stretch;
  }
`;

const CategoriesArea = styled.div`
  grid-area: "categories";
`;

const CatalogArea = styled.div`
  grid-area: catalog;
`;
