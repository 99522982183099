import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { getCatalog } from "../../services";
import useGetPath from "../../shared/hooks/useGetPath";
import { capitalize } from "../../utils/helper-fnc";

const { REACT_APP_BASE_URL } = process.env;

export const useGetProducts = (categoryId) => {
  const { i18n } = useTranslation();
  const getPath = useGetPath();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (categoryId) {
      getCatalog(categoryId)
        .then(({ data }) => {
          data?.data && setProducts(data.data);
        })
        .catch((errors) => {
          console.log("errors: ", errors);
        });
    }
  }, [categoryId]);

  return products.map((product) => ({
    id: product.id,
    name: product["name" + capitalize(i18n.language)],
    img:
      product.imagesWeb.length &&
      `${REACT_APP_BASE_URL}/${product.imagesWeb[0].relativeUrl}`,
    path: getPath(`/catalog/${categoryId}/products/${product.id}`),
    createdDate: product.createdUtc,
  }));
};
