import React from "react";

import Welcome from "./components/Welcome";
import Info from "./components/Info";
import Advantages from "./components/Advantages";

const Cooperation = () => {
  return (
    <div>
      <Welcome />
      <Info />
      <Advantages />
    </div>
  );
};

export default Cooperation;
