import React, { useEffect, useState } from "react";
import styled from "styled-components";
import GLightbox from "glightbox";
import { useTranslation } from "react-i18next";

import { SectionTitle } from "../../../../shared/components/elements";
import Container from "../../../../shared/wrappers/Container";
import Gallery1 from "../../../../assets/images/gallery/gallery-1.jpg";
import Gallery2 from "../../../../assets/images/gallery/gallery-2.jpg";
import Gallery3 from "../../../../assets/images/gallery/gallery-3.jpg";
import Gallery4 from "../../../../assets/images/gallery/gallery-4.jpg";
import Gallery5 from "../../../../assets/images/gallery/gallery-5.jpg";
import Gallery6 from "../../../../assets/images/gallery/gallery-6.jpg";
import Gallery7 from "../../../../assets/images/gallery/gallery-7.jpg";
import Gallery8 from "../../../../assets/images/gallery/gallery-8.jpg";
import Gallery9 from "../../../../assets/images/gallery/gallery-9.jpg";
import Gallery10 from "../../../../assets/images/gallery/gallery-10.jpg";
import Gallery11 from "../../../../assets/images/gallery/gallery-11.jpg";
import Gallery12 from "../../../../assets/images/gallery/gallery-12.jpg";
import Gallery13 from "../../../../assets/images/gallery/gallery-13.jpg";
import Gallery14 from "../../../../assets/images/gallery/gallery-14.jpg";
import Gallery15 from "../../../../assets/images/gallery/gallery-15.jpg";

const Gallery = () => {
  const [glightbox, setGlightbox] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!glightbox) {
      setGlightbox(
        GLightbox({
          skin: "clean",
          loop: true,
          selector: ".gallery-item",
        })
      );
    }

    return () => {
      glightbox && glightbox.destroy();
    };
  }, [glightbox]);

  return (
    <Wrapper>
      <Container>
        <SectionTitle>{t("Our everyday life")}</SectionTitle>

        <ImagesList isVisible={!!glightbox}>
          <ImageItem href={Gallery1} className="gallery-item">
            <img src={Gallery1} alt={t("Our everyday life")} />
          </ImageItem>
          <ImageItem href={Gallery2} className="gallery-item">
            <img src={Gallery2} alt={t("Our everyday life")} />
          </ImageItem>
          <ImageItem href={Gallery3} className="gallery-item">
            <img src={Gallery3} alt={t("Our everyday life")} />
          </ImageItem>
          <ImageItem href={Gallery4} className="gallery-item">
            <img src={Gallery4} alt={t("Our everyday life")} />
          </ImageItem>
          <ImageItem href={Gallery5} className="gallery-item">
            <img src={Gallery5} alt={t("Our everyday life")} />
          </ImageItem>
          <ImageItem href={Gallery6} className="gallery-item">
            <img src={Gallery6} alt={t("Our everyday life")} />
          </ImageItem>
          <ImageItem href={Gallery7} className="gallery-item">
            <img src={Gallery7} alt={t("Our everyday life")} />
          </ImageItem>
          <ImageItem href={Gallery8} className="gallery-item">
            <img src={Gallery8} alt={t("Our everyday life")} />
          </ImageItem>
          <ImageItem href={Gallery9} className="gallery-item">
            <img src={Gallery9} alt={t("Our everyday life")} />
          </ImageItem>
          <ImageItem href={Gallery10} className="gallery-item">
            <img src={Gallery10} alt={t("Our everyday life")} />
          </ImageItem>
          <ImageItem href={Gallery11} className="gallery-item">
            <img src={Gallery11} alt={t("Our everyday life")} />
          </ImageItem>
          <ImageItem href={Gallery12} className="gallery-item">
            <img src={Gallery12} alt={t("Our everyday life")} />
          </ImageItem>
          <ImageItem href={Gallery13} className="gallery-item">
            <img src={Gallery13} alt={t("Our everyday life")} />
          </ImageItem>
          <ImageItem href={Gallery14} className="gallery-item">
            <img src={Gallery14} alt={t("Our everyday life")} />
          </ImageItem>
          <ImageItem href={Gallery15} className="gallery-item">
            <img src={Gallery15} alt={t("Our everyday life")} />
          </ImageItem>
        </ImagesList>
      </Container>
    </Wrapper>
  );
};

export default Gallery;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.beige};
  padding: 64px 0;
`;

const ImagesList = styled.div`
  margin: -8px;
  display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
  flex-wrap: wrap;
`;

const ImageItem = styled.a`
  text-decoration: none;
  display: inline-flex;
  width: calc(100% / 5 - 16px);
  margin: 8px;
  background-color: ${({ theme }) => theme.colors.orange};
  cursor: pointer;

  @media screen and (max-width: 425px) {
    width: calc(100% / 2 - 16px);
  }

  img {
    max-width: 100%;
    transition: opacity 0.25s linear;

    &:hover {
      opacity: 0.5;
    }
  }
`;
