import React from "react";
import styled, { useTheme } from "styled-components";
import { Parallax } from "react-scroll-parallax";
import { useTranslation } from "react-i18next";

const parallaxScrollbar = {
  width: 3,
  height: 80,
  position: "absolute",
  right: -2,
  zIndex: 1,
};

const Statistics = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Column>
        <Parallax
          tagOuter="figure"
          y={[-1000, 600]}
          styleInner={{
            ...parallaxScrollbar,
            backgroundColor: theme.colors.yellow,
            top: "40%",
          }}
        />

        <Item>
          <ItemDigits>10</ItemDigits>
          <ItemText>{t("years of experience")}</ItemText>
        </Item>
      </Column>

      <Column>
        <Parallax
          tagOuter="figure"
          y={[800, -600]}
          styleInner={{
            ...parallaxScrollbar,
            backgroundColor: theme.colors.rose,
            top: "65%",
          }}
        />

        <Item>
          <ItemDigits>50 000</ItemDigits>
          <ItemText>{t("products per day")}</ItemText>
        </Item>

        <Item>
          <ItemDigits>4</ItemDigits>
          <ItemText>{t("innovative factories")}</ItemText>
        </Item>
      </Column>

      <Column>
        <Parallax
          tagOuter="figure"
          y={[-400, 800]}
          styleInner={{
            ...parallaxScrollbar,
            backgroundColor: theme.colors.purple,
            top: "20%",
          }}
        />

        <Item>
          <ItemDigits>1500</ItemDigits>
          <ItemText>{t("workers")}</ItemText>
        </Item>
      </Column>
    </Wrapper>
  );
};

export default Statistics;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  margin-left: 48px;

  @media screen and (max-width: 425px) {
    margin-left: 0;
    width: 100%;
    display: block;
    padding-bottom: 64px;

    &:after {
      content: "";
      display: block;
      clear: both;
      width: 100%;
    }
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  border-right: 1px solid ${({ theme }) => theme.colors.grey};
  padding: 20px 0 20px 30px;
  position: relative;
  width: calc(100% / 3);

  @media screen and (max-width: 425px) {
    width: calc(100% / 2);
    padding: 0;
    border-right: none;
    align-items: flex-start;
    justify-content: flex-start;

    &:nth-child(1) {
      float: left;
      padding-right: 8px;
      margin-top: 64px;
    }

    &:nth-child(2) {
      float: right;
      padding-left: 8px;
      /* margin-top: -64px; */
    }

    &:nth-child(3) {
      float: left;
      padding-right: 8px;
    }

    figure {
      display: none;
    }
  }
`;

const Item = styled.div`
  background-color: ${({ theme }) => theme.colors.beige};
  text-align: center;
  padding: 88px 16px;
  margin: 15px 0;
  width: 100%;

  ${Column}:first-child & {
    margin-top: 200px;
  }

  ${Column}:last-child & {
    margin-top: -100px;
  }

  @media screen and (max-width: 425px) {
    margin: 8px 0 !important;
  }
`;

const ItemDigits = styled.div`
  color: ${({ theme }) => theme.colors.orange};
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 8px;
`;

const ItemText = styled.div`
  color: ${({ theme }) => theme.colors.asphalt};
  font-size: 16px;
`;
