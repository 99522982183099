import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { sortOptions } from "../../../../utils/constants";
import logoImg from "../../../../assets/images/logo.svg";

const Products = ({ category, products, sortBy }) => {
  const [state, setState] = useState(products);

  useEffect(() => {
    setState(
      products.sort((a, b) => {
        switch (sortBy.value) {
          case sortOptions[3].value:
            return a.name < b.name ? 1 : -1;

          case sortOptions[2].value:
            return a.name > b.name ? 1 : -1;

          case sortOptions[1].value:
            return new Date(a.createdDate) - new Date(b.createdDate);

          default:
            return new Date(b.createdDate) - new Date(a.createdDate);
        }
      })
    );
  }, [sortBy, products]);

  return (
    <Wrapper>
      {category && <Title>{category.label}</Title>}

      <ProductsGrid>
        {state.map((product, index) => (
          <Product key={index || product.id} to={product.path}>
            <ProductImage>
              {product.img ? (
                <img src={product.img} alt={product.name} />
              ) : (
                <PhotoPlaceholder>
                  <img src={logoImg} alt={product.name} />
                </PhotoPlaceholder>
              )}
            </ProductImage>

            <ProductName>{product.name}</ProductName>
          </Product>
        ))}
      </ProductsGrid>
    </Wrapper>
  );
};

export default Products;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 32px;
  border-radius: 8px;

  @media screen and (max-width: 425px) {
    padding: 24px;
  }
`;

const Title = styled.h2`
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 24px;
`;

const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 32px;

  @media screen and (max-width: 425px) {
    grid-template-columns: 1fr;
  }
`;

const Product = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  aspect-ratio: 3/4;
`;

const ProductImage = styled.div`
  margin-bottom: 16px;
  flex: 1;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const PhotoPlaceholder = styled.div`
  padding: 48px 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.beige};
  filter: grayscale(1);

  img {
    max-width: 100%;
    opacity: 0.25;
  }
`;

const ProductName = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.coal};
`;
