import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

const instance = axios.create({
  baseURL: `${REACT_APP_BASE_URL}/api/`,
});

export const getCategories = () => instance.get("/categories");

export const getCatalog = (categoryId) =>
  instance.get(`/categories/${categoryId}/clothes`);

export const getProduct = (productId) => instance.get(`/clothes/${productId}`);
