import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { getProduct } from "../../services";
import { capitalize } from "../../utils/helper-fnc";

const { REACT_APP_BASE_URL } = process.env;

export const useGetProduct = (productId) => {
  const { i18n } = useTranslation();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    if (productId) {
      getProduct(productId)
        .then(({ data }) => {
          data?.data && setProduct(data.data);
        })
        .catch((errors) => {
          console.log("errors: ", errors);
        });
    }
  }, []);

  return (
    product && {
      id: product.id,
      name: product["name" + capitalize(i18n.language)],
      description: product["description" + capitalize(i18n.language)],
      details: product["details" + capitalize(i18n.language)],
      images:
        product.images.length &&
        product.images.map((image) => ({
          id: image.id,
          url: `${REACT_APP_BASE_URL}/${image.relativeUrl}`,
        })),
      imagesWeb:
        product.imagesWeb.length &&
        product.imagesWeb.map((image) => ({
          id: image.id,
          url: `${REACT_APP_BASE_URL}/${image.relativeUrl}`,
        })),
      imagesMobile:
        product.imagesMobile.length &&
        product.imagesMobile.map((image) => ({
          id: image.id,
          url: `${REACT_APP_BASE_URL}/${image.relativeUrl}`,
        })),
      url3D: product.url3D,
    }
  );
};
