import React from "react";

import Welcome from "./components/Welcome";
import Info from "./components/Info";

const DesignCenter = () => {
  return (
    <div>
      <Welcome />
      <Info />
    </div>
  );
};

export default DesignCenter;
