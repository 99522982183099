import { useTranslation } from "react-i18next";

import { languages } from "../../i18n";

const useGetPath = () => {
  const { i18n } = useTranslation();

  const getPath = (path) => {
    const lang = languages.find((language) => language.code === i18n.language);

    if (lang && !lang.default) {
      return `/${lang.code + path}`;
    }

    return path;
  };

  return getPath;
};

export default useGetPath;
