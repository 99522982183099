import React, { useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";

import { ReactComponent as IconArrowUp } from "../../../assets/images/icon-arrow-up.svg";
import { ReactComponent as IconArrowDown } from "../../../assets/images/icon-arrow-down.svg";
import Icon3D from "../../../assets/images/icon-3d.svg";
import logoImg from "../../../assets/images/logo.svg";

const NavSlider = ({ images, asNavForRef, selfRef, url3D }) => {
  const [settings, setSettings] = useState({
    arrows: false,
    slidesToShow: 4,
    vertical: true,
    verticalSwiping: true,
    focusOnSelect: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 3,
          vertical: false,
          verticalSwiping: false,
        },
      },
    ],
  });

  React.useEffect(() => {
    if (asNavForRef?.current) {
      setSettings({
        ...settings,
        asNavFor: asNavForRef.current,
      });
    }
  }, [asNavForRef]);

  const goToNext = () => {
    if (selfRef.current) {
      selfRef.current.slickNext();
    }
  };

  const goToPrev = () => {
    if (selfRef.current) {
      selfRef.current.slickPrev();
    }
  };

  return (
    images.length && (
      <SliderWrap>
        <IconHolder>
          <IconArrowUp onClick={goToPrev} />
        </IconHolder>

        <SliderList>
          <Slider {...settings} ref={selfRef}>
            {images.length &&
              images.map((item, index) => (
                <div key={index}>
                  <SliderItem>
                    <img src={item.url} alt={index} />
                  </SliderItem>
                </div>
              ))}

            {url3D && (
              <div>
                <SliderItem
                  style={{
                    backgroundImage: `url(${
                      images.length ? images[0].url : logoImg
                    })`,
                  }}
                >
                  <img src={Icon3D} alt="3D model" />
                </SliderItem>
              </div>
            )}
          </Slider>
        </SliderList>

        <IconHolder>
          <IconArrowDown onClick={goToNext} />
        </IconHolder>
      </SliderWrap>
    )
  );
};

export default NavSlider;

const SliderWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  max-width: calc(100vw - 32px);

  @media screen and (max-width: 425px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const IconHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-child {
    order: 1;
  }

  &:last-child {
    order: 3;
  }

  svg {
    cursor: pointer;
    width: 30px;
    opacity: 0.5;
  }

  @media screen and (max-width: 425px) {
    margin: 8px 16px;

    svg {
      width: 20px;
    }

    &:first-child {
      svg {
        transform: rotate(-90deg);
      }
    }

    &:last-child {
      order: 2;

      svg {
        transform: rotate(-90deg);
      }
    }
  }
`;

const SliderList = styled.div`
  width: 100%;
  order: 2;
  max-height: 598px;
  overflow: hidden;

  @media screen and (max-width: 425px) {
    flex: initial;
    order: 3;
    max-height: auto;
  }
`;

const SliderItem = styled.div`
  width: 130px;
  height: 130px;
  border-radius: 6px;
  margin: 8px 0;
  opacity: 0.6;
  font-size: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid ${({ theme }) => theme.colors.white};

  @media screen and (max-width: 425px) {
    margin: 8px;
    width: 100px;
    height: 100px;
  }

  .slick-current &,
  &.current {
    opacity: 1;
    border: 1px solid ${({ theme }) => theme.colors.orange};
  }

  img {
    max-width: 100%;
    max-height: 100%;
    font-size: 0;
  }
`;
