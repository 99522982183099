import React from "react";
import styled from "styled-components";

import Container from "../../../../shared/wrappers/Container";
import Info from "./Info";
import Statistics from "./Statistics";

const Advantages = () => {
  return (
    <Wrapper>
      <Container>
        <Content>
          <Info />

          <Statistics />
        </Content>
      </Container>
    </Wrapper>
  );
};

export default Advantages;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: hidden;
`;

const Content = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 425px) {
    flex-direction: column;
  }
`;
