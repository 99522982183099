import React from "react";
import styled from "styled-components";
import { darken, lighten, rgba, transparentize } from "polished";

const Button = ({ outlined, children, ...props }) => {
  return (
    <Wrapper outlined={outlined} {...props}>
      {children}
    </Wrapper>
  );
};

export default Button;

const Wrapper = styled.button`
  border: ${({ theme, outlined }) =>
    outlined ? `1px solid ${theme.colors.orange}` : "none"};
  border-radius: 12px;
  font-size: 18px;
  padding: 12px 32px;
  cursor: pointer;
  color: ${({ theme, outlined }) =>
    outlined ? theme.colors.orange : theme.colors.white};
  background: ${({ theme, outlined }) =>
    outlined
      ? rgba(theme.colors.white, 0)
      : `linear-gradient(
            135deg, 
            ${darken(0.025, theme.colors.orange)} 0px, 
            ${lighten(0.1, theme.colors.orange)} 100%
          )`};
  transition: all 0.25s linear;

  &:hover {
    background: ${({ theme, outlined }) =>
      outlined
        ? transparentize(0.9, theme.colors.orange)
        : `linear-gradient(
            45deg, 
            ${darken(0.05, theme.colors.orange)} 0px, 
            ${lighten(0.05, theme.colors.orange)} 100%
          )`};
  }
`;
