import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Container from "../../../../shared/wrappers/Container";
import {
  SectionText,
  SectionTitle,
} from "../../../../shared/components/elements";
import branchAkjol from "../../../../assets/images/branch-akjol.png";
import branchChui from "../../../../assets/images/branch-chui.png";
import branchKyzylKyia from "../../../../assets/images/branch-kyzyl-kyia.png";

const Branches = () => {
  const { t } = useTranslation();

  const items = [
    {
      img: branchAkjol,
      name: t("Ak-Jol"),
      description: t("Ak-Jol description"),
    },
    {
      img: branchChui,
      name: t("Chui"),
      description: t("Chui description"),
    },
    {
      img: branchKyzylKyia,
      name: t("Kyzyl-Kia"),
      description: t("Kyzyl-Kia description"),
    },
  ];

  return (
    <Wrapper>
      <Container>
        <SectionTitle>{t("Our branches")}</SectionTitle>

        <List>
          {items.map((item, index) => (
            <Item key={index}>
              <ImageHolder>
                <img src={item.img} alt={`Филиал ${item.name}`} />
              </ImageHolder>
              <Name>{item.name}</Name>
              <SectionText>{item.description}</SectionText>
            </Item>
          ))}
        </List>
      </Container>
    </Wrapper>
  );
};

export default Branches;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.beige};
  padding: 70px 0;
`;

const List = styled.div`
  display: flex;
  margin: 0 -15px;

  @media screen and (max-width: 425px) {
    flex-direction: column;
    margin: 0;
  }
`;

const Item = styled.div`
  flex: 1;
  width: calc(100% / 3);
  margin: 0 15px;

  @media screen and (max-width: 425px) {
    width: 100%;
    margin: 15px 0;
  }
`;

const ImageHolder = styled.div`
  margin-bottom: 16px;

  img {
    max-width: 100%;

    @media screen and (max-width: 425px) {
      width: 100%;
    }
  }
`;

const Name = styled.h5`
  color: ${({ theme }) => theme.colors.coal};
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 8px;
`;
