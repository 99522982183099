import React, { useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useClickAway } from "react-use";

import Current from "./Current";
import { languages } from "../../../i18n";
import ItemsList from "../ItemsList";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [visible, setVisibility] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const listRef = useRef(null);

  useClickAway(listRef, () => {
    setVisibility(false);
  });

  const onLanguageChange = (language) => {
    if (language.code !== i18n.language) {
      let path = location.pathname;

      if (language.default) {
        path = location.pathname.replace("/" + i18n.language + "/", "/");
      } else {
        path = `/${language.code}${location.pathname}`;
      }

      i18n.changeLanguage(language.code).then(() => {
        history.push(path);
        setVisibility(false);
      });
    }
  };

  return (
    <Wrapper ref={listRef}>
      <Current
        current={languages.find((lang) => lang.code === i18n.language)}
        visible={visible}
        onClick={() => setVisibility(!visible)}
      />

      <ListHolder visible={visible}>
        <ItemsList
          items={languages}
          onClick={onLanguageChange}
          current={i18n.language}
        />
      </ListHolder>
    </Wrapper>
  );
};

export default LanguageSwitcher;

const Wrapper = styled.div`
  position: relative;
`;

const ListHolder = styled.div`
  position: absolute;
  left: 50%;
  top: calc(100% + 4px);
  z-index: 1;
  transform: translate(-50%, ${({ visible }) => (visible ? 0 : "-155%")});
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: all 0.25s linear;
`;
