import React, { useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useClickAway } from "react-use";

import Current from "./Current";
import ItemsList from "../../../../shared/components/ItemsList";
import { sortOptions } from "../../../../utils/constants";

const Sort = ({ sortBy, setSortBy }) => {
  const [visible, setVisibility] = useState(false);
  const { t } = useTranslation();
  const listRef = useRef(null);

  useClickAway(listRef, () => {
    setVisibility(false);
  });

  return (
    <SortWrapper ref={listRef}>
      <Current
        value={t(`common:${sortBy.label}`)}
        onClick={() => setVisibility(!visible)}
        visible={visible}
      />

      <ListHolder visible={visible}>
        <ItemsList
          items={sortOptions.map((option) => ({
            ...option,
            label: t(`common:${option.label}`),
          }))}
          onClick={(option) => {
            setSortBy(option);
            setVisibility(false);
          }}
          current={sortBy.value}
        />
      </ListHolder>
    </SortWrapper>
  );
};

export default Sort;

const SortWrapper = styled.div`
  position: relative;
`;

const ListHolder = styled.div`
  position: absolute;
  right: 0;
  top: calc(100% + 4px);
  z-index: 1;
  transform: translateY(${({ visible }) => (visible ? 0 : "-100%")});
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  transition: all 0.25s linear;
`;
