import React, { useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";

const MainSlider = ({ images, asNavForRef, selfRef, url3D }) => {
  const [settings, setSettings] = useState({
    arrows: false,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
  });

  React.useEffect(() => {
    if (asNavForRef?.current) {
      setSettings({
        ...settings,
        asNavFor: asNavForRef.current,
      });
    }
  }, [asNavForRef]);

  return (
    <Slider {...settings} ref={selfRef}>
      {images?.length &&
        images.map((item, index) => (
          <div key={index}>
            <SliderItem
            // style={{ backgroundImage: `url("${item.url}")` }}
            // test
            >
              <img src={item.url} alt={index} />
            </SliderItem>
          </div>
        ))}

      {url3D && (
        <div key="3Dmodel">
          <SliderItem>
            <iframe
              src={url3D}
              title="3D model"
              style={{ width: "100%", height: "100%" }}
              onClick={() => console.log("Iframe click")}
              allowFullScreen
            />
          </SliderItem>
        </div>
      )}
    </Slider>
  );
};

export default MainSlider;

const SliderItem = styled.div`
  width: 100%;
  height: 640px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: ${({ theme }) => theme.colors.white};

  @media screen and (max-width: 425px) {
    height: calc(100vw - 32px);
    width: 100%;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 6px;
  }

  iframe {
    display: none;
  }

  .slick-slide.slick-active.slick-current & iframe {
    display: initial;
  }
`;
