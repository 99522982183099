import React from "react";
import { useTranslation } from "react-i18next";

import { SectionText, SectionTitle } from "../../../shared/components/elements";
import { ReactComponent as IconStudio } from "../../../assets/images/icon-studio-orange.svg";
import Accordeon from "../../../shared/components/Accordeon";

const Info = ({ product }) => {
  const { t } = useTranslation();

  return (
    <div>
      <SectionTitle>{product.name}</SectionTitle>
      <SectionText>{product.description}</SectionText>

      <Accordeon icon={IconStudio} name={t("Details")}>
        <div dangerouslySetInnerHTML={{ __html: product.details }} />
      </Accordeon>
    </div>
  );
};

export default Info;
