import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import InfoContent from "../../../../shared/components/InfoContent";
import CooperationImg2 from "../../../../assets/images/cooperation-img-2.png";
import CooperationImg3 from "../../../../assets/images/cooperation-img-3.png";

const Info = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <InfoContent
        title={t("Who are we?")}
        text={t("cooperationWhoWeAreText")}
        image={CooperationImg2}
      />

      <InfoContent
        title={t("Our philosophy")}
        text={t("ourPhilosophyText")}
        image={CooperationImg3}
        reversed
      />
    </Wrapper>
  );
};

export default Info;

const Wrapper = styled.div`
  padding: 48px 0;
`;
