import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import { ReactComponent as FlagRu } from "./assets/images/flag-russia-rectangle.svg";
import { ReactComponent as FlagUS } from "./assets/images/flag-usa-rectangle.svg";

export const languages = [
  {
    id: "en",
    code: "en",
    name: "English",
    flag: FlagUS,
    default: true,
  },
  {
    id: "ru",
    code: "ru",
    name: "Russian",
    flag: FlagRu,
    default: false,
  },
];

export const defaultLng = languages[0].code;

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // lng: defaultLng,
    backend: {
      loadPath: "/assets/i18n/{{ns}}/{{lng}}.json",
    },
    supportedLngs: languages.map((language) => language.code),
    fallbackLng: languages.map((language) => language.code),
    debug: true,
    ns: ["common", "translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    react: {
      wait: true,
    },
  });

export default i18n;
