import React from "react";
import Slider from "react-slick";
import styled from "styled-components";

import logoMagnit from "../../../../assets/images/logo-magnit.png";
import logoDetskiiMir from "../../../../assets/images/logo-detskii-mir.png";
import logoFamilia from "../../../../assets/images/logo-familia.png";
import logoFixPrice from "../../../../assets/images/logo-fix-price.png";
import logoPromax from "../../../../assets/images/logo-profmax.png";
import logoSela from "../../../../assets/images/logo-sela.png";
import logoMagnum from "../../../../assets/images/logo-magnum.png";
import logoSmall from "../../../../assets/images/logo-small.png";
import logoDina from "../../../../assets/images/logo-dina.png";

const brands = [
  {
    imgUrl: logoMagnit,
    name: "Магнит",
  },
  {
    imgUrl: logoDetskiiMir,
    name: "Детский мир",
  },
  {
    imgUrl: logoFamilia,
    name: "Familia",
  },
  {
    imgUrl: logoFixPrice,
    name: "Fix Price",
  },
  {
    imgUrl: logoPromax,
    name: "Profmax",
  },
  {
    imgUrl: logoSela,
    name: "Sela",
  },
  {
    imgUrl: logoMagnum,
    name: "Magnum",
  },
  {
    imgUrl: logoSmall,
    name: "Small",
  },
  {
    imgUrl: logoDina,
    name: "Dina",
  },
];

const Brands = () => {
  const settings = {
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 5000,
    cssEase: "linear",
    variableWidth: true,
    pauseOnHover: false,
  };

  return (
    <Wrapper>
      <Slider {...settings}>
        {brands.map((item, index) => (
          <Item key={index}>
            <img src={item.imgUrl} alt={item.name} />
          </Item>
        ))}
      </Slider>
    </Wrapper>
  );
};

export default Brands;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.beige};
  padding: 24px 0;
`;

const Item = styled.div`
  padding: 10px 48px;
`;
