import React from "react";
import styled from "styled-components";

const Container = ({ children, ...rest }) => {
  return <ContainerWrapper {...rest}>{children}</ContainerWrapper>;
};

export default Container;

const ContainerWrapper = styled.div`
  max-width: 1170px;
  width: calc(100% - 110px);
  margin: 0 auto;

  @media screen and (max-width: 425px) {
    width: calc(100% - 32px);
  }
`;
