import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ReactComponent as IconSort } from "../../../../assets/images/icon-sort.svg";

const Current = ({ value, visible, ...rest }) => {
  const { t } = useTranslation();

  return (
    <CurrentWrapper {...rest}>
      <SortIconHolder>
        <IconSort />
      </SortIconHolder>

      <Label>{t("common:Sort by")}</Label>
      <Name opened={visible}>{value}</Name>
    </CurrentWrapper>
  );
};

export default Current;

const CurrentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.asphalt};
  cursor: pointer;

  @media screen and (max-width: 425px) {
    flex-direction: row;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 8px;
    border-radius: 8px;
    width: 100%;
  }
`;

const SortIconHolder = styled.div`
  display: none;

  @media screen and (max-width: 425px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }
`;

const Label = styled.div`
  font-size: 12px;
  font-weight: 600;

  @media screen and (max-width: 425px) {
    font-size: 14px;
    font-weight: 400;

    &:after {
      display: none;
    }
  }

  &:after {
    content: ":";
  }
`;

const Name = styled.div`
  font-size: 12px;
  font-weight: 400;

  @media screen and (max-width: 425px) {
    display: none;
  }

  &:after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: ${({ opened }) => (opened ? 0 : "6px")} solid
      ${({ theme }) => theme.colors.coal};
    border-bottom: ${({ opened }) => (opened ? "6px" : 0)} solid
      ${({ theme }) => theme.colors.coal};
    margin-left: 6px;
    margin-bottom: 1px;
    transition: all 0.25s linear;
  }
`;
