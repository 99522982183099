import { useTranslation } from "react-i18next";

import { ReactComponent as IconDeliver } from "../../../../assets/images/icon-delivery.svg";
import { ReactComponent as IconPriceTag } from "../../../../assets/images/icon-price-tag.svg";
import { ReactComponent as IconStudio } from "../../../../assets/images/icon-studio.svg";
import { ReactComponent as IconBabyBib } from "../../../../assets/images/icon-baby-bib.svg";
import { ReactComponent as IconSewing } from "../../../../assets/images/icon-sewing-colored.svg";
import { ReactComponent as IconSale } from "../../../../assets/images/icon-sale.svg";

const useStaticData = () => {
  const { t } = useTranslation();

  const advantages = [
    {
      icon: IconDeliver,
      title: t("advantage 1 name"),
      text: t("advantage 1 text"),
    },
    {
      icon: IconPriceTag,
      title: t("advantage 2 name"),
      text: t("advantage 2 text"),
    },
    {
      icon: IconStudio,
      title: t("advantage 3 name"),
      text: t("advantage 3 text"),
    },
    {
      icon: IconBabyBib,
      title: t("advantage 4 name"),
      text: t("advantage 4 text"),
    },
    {
      icon: IconSewing,
      title: t("advantage 5 name"),
      text: t("advantage 5 text"),
    },
    {
      icon: IconSale,
      title: t("advantage 6 name"),
      text: t("advantage 6 text"),
    },
  ];

  return advantages;
};

export default useStaticData;
