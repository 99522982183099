import React, { createElement } from "react";
import styled from "styled-components";

import { ReactComponent as ArrowDown } from "../../../assets/images/arrow-down.svg";

const Current = ({ current, visible, onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <LanguageFlag>{createElement(current.flag)}</LanguageFlag>

      <LanguageName>{current.code}</LanguageName>

      <Arrow visible={visible}>
        <ArrowDown />
      </Arrow>
    </Wrapper>
  );
};

export default Current;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const CurrentItem = styled.div`
  display: inline-flex;
  align-items: center;
`;

const LanguageFlag = styled(CurrentItem)`
  margin-right: 6px;
  width: 28px;

  @media screen and (max-width: 425px) {
    width: 24px;
  }

  svg {
    max-width: 100%;
  }
`;

const LanguageName = styled(CurrentItem)`
  text-transform: uppercase;
  line-height: 1;
  font-size: 14px;
`;

const Arrow = styled(CurrentItem)`
  margin-left: 2px;
  width: 14px;
  transform: scaleY(${({ visible }) => (visible ? -1 : 1)});
  transition: transform 0.3s linear;

  svg {
    max-width: 100%;
  }
`;
