import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-use";

import Container from "../../wrappers/Container";
import FooterImg from "../../../assets/images/footer-img.jpeg";
import { ReactComponent as IconMail } from "../../../assets/images/icon-mail.svg";
import { ReactComponent as IconCall } from "../../../assets/images/icon-call.svg";

const Footer = () => {
  const { t } = useTranslation();
  const [contacts, setContacts] = useState({
    tel: "+996 995 00 12 48",
    mail: "sales@coolbrs.com",
  });
  const location = useLocation();

  useEffect(() => {
    console.log("location: ", location);

    if (location?.pathname.includes("career")) {
      setContacts({
        tel: "+996 701 465 246",
        mail: "hr@coolbrs.com",
      });
    } else {
      setContacts({
        tel: "+996 995 00 12 48",
        mail: "sales@coolbrs.com",
      });
    }
  }, [location]);

  return (
    <Wrapper>
      <Container>
        <Content>
          <TextHolder>
            <TextInvite>{t("common:We invite you")}</TextInvite>
            <TextInvite accent>{t("common:to cooperate")}</TextInvite>

            <Email href={`tel:${contacts.tel}`}>
              <IconHolder>
                <IconCall />
              </IconHolder>
              {contacts.tel} (Whatsapp)
            </Email>

            <Email href={`mailto:${contacts.mail}`}>
              <IconHolder>
                <IconMail />
              </IconHolder>
              {contacts.mail}
            </Email>

            <LinkList>
              <Link
                href="https://www.facebook.com/profile.php?id=105741925369756&sk=about"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </Link>
              <Link
                href="https://www.instagram.com/coolbros_com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </Link>
              <Link
                href="https://www.youtube.com/channel/UC3VWwFzHVRRJS1nxJbYzQVw"
                target="_blank"
                rel="noopener noreferrer"
              >
                Youtube
              </Link>
            </LinkList>
          </TextHolder>

          <ImageHolder>
            <img src={FooterImg} alt="Sewing" />
          </ImageHolder>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.div`
  padding: 48px 0;
`;

const Content = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 425px) {
    flex-direction: column-reverse;
  }
`;

const TextHolder = styled.div`
  flex: 1;

  @media screen and (max-width: 425px) {
    margin-top: 32px;
  }
`;

const TextInvite = styled.p`
  font-size: 38px;
  font-weight: ${({ accent }) => (accent ? 700 : 300)};
  color: ${({ theme, accent }) =>
    accent ? theme.colors.orange : theme.colors.coal};

  @media screen and (max-width: 425px) {
    font-size: 28px;
  }
`;

const Email = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.coal};
  margin-top: 32px;
  display: flex;
  align-items: center;
  font-size: 28px;
  font-weight: 300;
  line-height: 1;

  @media screen and (max-width: 425px) {
    font-size: 18px;
  }
`;

const IconHolder = styled.div`
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;

  @media screen and (max-width: 425px) {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }

  svg {
    max-width: 100%;
    width: 100%;
    height: 100%;
  }
`;

const LinkList = styled.div`
  display: flex;
  align-items: center;
  margin-top: 82px;
`;

const Link = styled.a`
  text-decoration: none;
  margin-right: 48px;
  color: ${({ theme }) => theme.colors.coal};

  &:hover {
    color: ${({ theme }) => theme.colors.orange};
  }
`;

const ImageHolder = styled.div`
  max-width: 60%;

  img {
    max-width: 100%;
  }
`;
