import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import InfoContent from "../../../../shared/components/InfoContent";
import DesignCenterImg2 from "../../../../assets/images/design-center-img-2.png";
import DesignCenterImg3 from "../../../../assets/images/design-center-img-3.png";

const Info = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <InfoContent
        title={t("What are we doing?")}
        text={
          <>
            <p>{t("In experimental – technical center of our Company")}</p>

            <br />

            <ul style={{ listStyle: "inside" }}>
              <li>{t("clothing models are being developed operatively")}</li>
              <li>{t("experimental samples are being sewned off")}</li>
              <li>
                {t(
                  "technological passports for launching an order into mass production at our factories are being prepared"
                )}
              </li>
              <li>
                {t("templates for manufacturing products of any complexities")}
              </li>
              <li>
                {t(
                  "various options of construction and design solutions are being offered"
                )}
              </li>
            </ul>
          </>
        }
        image={DesignCenterImg2}
        reversed
      />

      <InfoContent
        title={t("Standards of quality")}
        text={
          <>
            <p>{t("standardsOfQuality1")}</p>
            <p>{t("standardsOfQuality2")}</p>
          </>
        }
        image={DesignCenterImg3}
      />
    </Wrapper>
  );
};

export default Info;

const Wrapper = styled.div`
  padding: 48px 0;
`;
