import React from "react";
import styled from "styled-components";

import Container from "../../wrappers/Container";
import { SectionTitle, SectionText } from "../elements";
import { rgba } from "polished";

const InfoContent = ({ title, text, image, reversed }) => {
  return (
    <Container>
      <Content reversed={reversed}>
        <TextHolder>
          {title && <SectionTitle>{title}</SectionTitle>}
          {text && <SectionText alone>{text}</SectionText>}
        </TextHolder>

        {image && (
          <ImageHolder reversed={reversed}>
            <img src={image} alt={title} />
          </ImageHolder>
        )}
      </Content>
    </Container>
  );
};

export default InfoContent;

const Content = styled.div`
  display: flex;
  padding: 48px 0;
  flex-direction: ${({ reversed }) => (reversed ? "row-reverse" : "row")};

  @media screen and (max-width: 425px) {
    flex-direction: column;
    padding: 0;
    margin-bottom: 32px;
  }
`;

const TextHolder = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.beige};
  padding: 64px;
  flex: 1;

  @media screen and (max-width: 425px) {
    padding: 48px 16px 16px;
  }
`;

const ImageHolder = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${({ reversed }) => (reversed ? "flex-start" : "flex-end")};

  &:after {
    content: "";
    display: block;
    height: 100%;
    width: 64px;
    position: absolute;
    top: 0;
    left: ${({ reversed }) => (reversed ? "initial" : 0)};
    right: ${({ reversed }) => (reversed ? 0 : "initial")};
    background-color: ${({ theme }) => theme.colors.beige};
  }

  img {
    max-width: 100%;
    position: relative;
    z-index: 1;
    box-shadow: ${({ reversed }) => (reversed ? "10px" : "-10px")} 0 10px 0
      ${({ theme }) => rgba(theme.colors.coal, 0.15)};
  }

  @media screen and (max-width: 425px) {
    &:after {
      display: none;
    }

    img {
      box-shadow: none;
    }
  }
`;
