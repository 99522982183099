import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import {
  LeadText,
  LeadTextAccent,
  SectionText,
} from "../../../../shared/components/elements";
import Container from "../../../../shared/wrappers/Container";
import CareerImg1 from "../../../../assets/images/career-img-1.png";

const Welcome = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Container>
        <Content>
          <TextContent>
            <LeadText>
              <LeadTextAccent>{t("common:Career")}</LeadTextAccent>
            </LeadText>

            <SectionText>{t("careerWelcome")}</SectionText>
          </TextContent>

          <ImageHolder>
            <img src={CareerImg1} alt="Cooperation" />
          </ImageHolder>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default Welcome;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.beige};
  padding-top: 70px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 425px) {
    flex-direction: column-reverse;
  }
`;

const TextContent = styled.div`
  width: 42%;
  padding: 32px 0;
  margin-right: 64px;

  @media screen and (max-width: 425px) {
    width: 100%;
    margin-right: 0;
  }
`;

const ImageHolder = styled.div`
  margin-left: auto;
  padding: 96px 32px;

  @media screen and (max-width: 425px) {
    padding: 16px;
  }

  img {
    max-width: 100%;
  }
`;
