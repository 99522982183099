import React from "react";

import Welcome from "./components/Welcome";
import Advantages from "./components/Advantages";
import VideoReview from "./components/VideoReview";
import Brands from "./components/Brands";
import Guarantee from "./components/Guarantee";
import Sections from "./components/Sections";
import Branches from "./components/Branches";

const Home = () => {
  return (
    <div>
      <Welcome />
      <Advantages />
      <VideoReview />
      <Brands />
      <Guarantee />
      <Sections />
      <Branches />
    </div>
  );
};

export default Home;
