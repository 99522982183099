import designCenter from "../assets/images/design-center.png";
import production from "../assets/images/production.png";
import career from "../assets/images/career.png";
import catalog from "../assets/images/catalog.png";
import { ReactComponent as IconDress } from "../assets/images/icon-dress.svg";
import { ReactComponent as IconDrawing } from "../assets/images/icon-drawing.svg";
import { ReactComponent as IconWear } from "../assets/images/icon-wear.svg";
import { ReactComponent as IconMannequin } from "../assets/images/icon-mannequin.svg";

import Home from "../pages/Home";
import Cooperation from "../pages/Cooperation";
import DesignCenter from "../pages/DesignCenter";
import Production from "../pages/Production";
import Career from "../pages/Career";
import Catalog from "../pages/Catalog";
import Product from "../pages/Product";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    exact: true,
  },
  {
    path: "/cooperation",
    name: "Cooperation",
    component: Cooperation,
    exact: true,
    nav: true,
  },
  {
    path: "/design-center",
    name: "Design center",
    component: DesignCenter,
    exact: true,
    icon: IconDress,
    background: designCenter,
    nav: true,
    homePageNav: true,
  },
  {
    path: "/production",
    name: "Production",
    component: Production,
    exact: true,
    icon: IconMannequin,
    background: production,
    nav: true,
    homePageNav: true,
  },
  {
    path: "/career",
    name: "Career",
    component: Career,
    exact: true,
    icon: IconDrawing,
    background: career,
    nav: true,
    homePageNav: true,
  },
  {
    path: "/catalog",
    name: "Catalog",
    component: Catalog,
    exact: true,
    icon: IconWear,
    background: catalog,
    nav: true,
    homePageNav: true,
  },
  {
    path: "/catalog/:categoryId",
    name: "Catalog",
    component: Catalog,
    exact: true,
  },
  {
    path: "/catalog/:categoryId/products/:productId",
    name: "Product",
    component: Product,
    exact: true,
  },
];

export default routes;

export const homePageRoutes = routes.filter((route) => route.homePageNav);
export const navRoutes = routes.filter((route) => route.nav);
