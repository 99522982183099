import React from "react";
import styled from "styled-components";
import { transparentize } from "polished";
import { NavLink as Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { navRoutes } from "../../../routes";
import { ReactComponent as IconMail } from "../../../assets/images/icon-mail.svg";
import { ReactComponent as Instagram } from "../../../assets/images/instagram.svg";
import { ReactComponent as Facebook } from "../../../assets/images/facebook.svg";
import useGetPath from "../../hooks/useGetPath";

const Navigation = ({ closeNav }) => {
  const { t } = useTranslation();
  const getPath = useGetPath();

  return (
    <NavWrapper>
      <NavList>
        {navRoutes.map((route, index) => (
          <NavItem key={index}>
            <NavLink
              to={getPath(route.path)}
              activeClassName="current"
              onClick={closeNav}
            >
              {t(`common:${route.name}`)}
            </NavLink>
          </NavItem>
        ))}
      </NavList>

      <ContactsList>
        <ContactLink href="mailto:info@coolbrs.com">
          <IconMail />
          <ContactText>info@coolbrs.com</ContactText>
        </ContactLink>

        <ContactLink
          href="https://www.instagram.com/coolbros_com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Instagram />
        </ContactLink>

        <ContactLink
          href="https://www.facebook.com/profile.php?id=105741925369756&sk=about"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Facebook />
        </ContactLink>
      </ContactsList>
    </NavWrapper>
  );
};

export default Navigation;

const NavWrapper = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 425px) {
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    padding-top: 56px;
  }
`;

const NavList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;

  @media screen and (max-width: 425px) {
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const NavItem = styled.li`
  margin: 0 12px;
  font-size: 14px;
  line-height: 1.5;

  @media screen and (max-width: 425px) {
    text-align: center;
    margin: 12px;
    font-size: 24px;
  }
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.asphalt};
  display: inline-block;
  padding: 24px 0;
  position: relative;

  &.current {
    color: ${({ theme }) => theme.colors.orange};

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: ${({ theme }) => theme.colors.orange};
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  &:hover:not(.current) {
    color: ${({ theme }) => transparentize(0.4, theme.colors.orange)};
  }

  @media screen and (max-width: 425px) {
    padding: 0;

    &.current:after {
      display: none;
    }
  }
`;

const ContactsList = styled.div`
  display: flex;

  @media screen and (max-width: 425px) {
    padding: 12px;
    justify-content: flex-end;
    width: 100%;
  }
`;

const ContactLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: 0 8px;

  @media screen and (max-width: 425px) {
    &:first-child {
      margin-right: auto;
    }
  }
`;

const ContactText = styled.span`
  margin-left: 8px;
  font-size: 14px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.black};

  @media screen and (max-width: 425px) {
    font-size: 18px;
  }
`;
