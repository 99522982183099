import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { getCategories } from "../../services";
import useGetPath from "../../shared/hooks/useGetPath";
import { capitalize } from "../../utils/helper-fnc";

const { REACT_APP_BASE_URL } = process.env;

export const useGetCategories = () => {
  const { i18n } = useTranslation();
  const getPath = useGetPath();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getCategories()
      .then(({ data }) => {
        data?.data && setCategories(data.data);
      })
      .catch((errors) => {
        console.log("errors: ", errors);
      });
  }, []);

  return categories.map((category) => ({
    id: category.id,
    label: category["name" + capitalize(i18n.language)],
    img: category?.logo && `${REACT_APP_BASE_URL}/${category.logo.relativeUrl}`,
    path: getPath(`/catalog/${category.id}`),
  }));
};
