import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import InfoContent from "../../../../shared/components/InfoContent";
import CareerImg2 from "../../../../assets/images/career-img-2.png";
import CareerImg3 from "../../../../assets/images/career-img-3.png";

const Info = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <InfoContent
        title={t("Oportunities")}
        text={t("oportunitiesText")}
        image={CareerImg2}
      />

      <InfoContent
        title={t("About personnel policy")}
        text={
          <div>
            <AccentText>{t("aboutPersonnelPolicyText")}</AccentText>

            <ul style={{ listStyle: "inside" }}>
              <li>{t("aboutPersonnelPolicyList11")}</li>
              <li>{t("aboutPersonnelPolicyList12")}</li>
              <li>{t("aboutPersonnelPolicyList13")}</li>
            </ul>

            <br />

            <p>{t("aboutPersonnelPolicyText2")}</p>

            <br />

            <ul style={{ listStyle: "inside" }}>
              <li>{t("aboutPersonnelPolicyList21")}</li>
              <li>{t("aboutPersonnelPolicyList22")}</li>
              <li>{t("aboutPersonnelPolicyList23")}</li>
              <li>{t("aboutPersonnelPolicyList23")}</li>
            </ul>
          </div>
        }
        image={CareerImg3}
        reversed
      />
    </Wrapper>
  );
};

export default Info;

const Wrapper = styled.div`
  padding: 48px 0;
`;

const AccentText = styled.div`
  padding-left: 20px;
  position: relative;
  margin-bottom: 24px;

  &:before {
    content: "";
    display: block;
    height: 100%;
    width: 4px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${({ theme }) => theme.colors.orange};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
