import React from "react";

import Welcome from "./components/Welcome";
import Info from "./components/Info";
import Gallery from "./components/Gallery";

const Career = () => {
  return (
    <div>
      <Welcome />
      <Info />
      <Gallery />
    </div>
  );
};

export default Career;
