import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { transparentize } from "polished";

import { ReactComponent as Logo } from "../../../assets/images/logo.svg";
import Container from "../../wrappers/Container";
import Navigation from "../Navigation";
import LanguageSwitcher from "../LanguageSwitcher";
import useGetPath from "../../hooks/useGetPath";

const Header = () => {
  const [navActive, setNavActivity] = useState(false);
  const getPath = useGetPath();

  const closeNav = () => setNavActivity(false);

  return (
    <Wrapper>
      <Container>
        <Content>
          <LogoContainer>
            <Link to={getPath("/")}>
              <Logo />
            </Link>
          </LogoContainer>

          <NavContainer active={navActive}>
            <Navigation closeNav={closeNav} />
          </NavContainer>

          <ToolsContainer>
            <LanguageSwitcher />

            <NavToggle
              active={navActive}
              onClick={() => setNavActivity(!navActive)}
            />
          </ToolsContainer>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default Header;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  background-color: ${({ theme }) => transparentize(0.25, theme.colors.beige)};
  backdrop-filter: blur(4px);

  @media screen and (max-width: 425px) {
    padding: 16px 0;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoContainer = styled.div`
  margin-right: 20px;
  padding-right: 32px;
  position: relative;
  z-index: 1;

  svg {
    height: 100%;
    width: auto;
  }

  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 24px;
    width: 1px;
    background-color: ${({ theme }) =>
      transparentize(0.6, theme.colors.orange)};
  }

  @media screen and (max-width: 425px) {
    height: 24px;

    &:after {
      display: none;
    }
  }
`;

const NavContainer = styled.div`
  flex: 1;
  width: 100%;
  transition: all 0.25s linear;

  @media screen and (max-width: 425px) {
    position: fixed;
    top: ${({ active }) => (active ? 0 : "-100vh")};
    opacity: ${({ active }) => (active ? 1 : 0)};
    left: 0;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.beige};
  }
`;

const ToolsContainer = styled.div`
  margin-left: 32px;
  display: flex;
  align-items: center;
`;

const NavToggle = styled.div`
  width: 24px;
  height: 12px;
  position: relative;
  margin-left: 16px;
  cursor: pointer;
  display: none;
  transition: all 0.25s linear;

  @media screen and (max-width: 425px) {
    display: block;
  }

  &:before,
  &:after {
    content: "";
    display: block;
    height: 3px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.orange};
    position: absolute;
    left: 0;
    transition: all 0.25s linear;
  }

  &:before {
    top: ${({ active }) => (active ? `4.5px` : 0)};
    transform: rotate(${({ active }) => (active ? `-135deg` : 0)});
  }

  &:after {
    bottom: ${({ active }) => (active ? `4.5px` : 0)};
    transform: rotate(${({ active }) => (active ? `135deg` : 0)});
  }
`;
