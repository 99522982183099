import React, { useEffect, useState } from "react";
import styled from "styled-components";
import GLightbox from "glightbox";
import { useTranslation } from "react-i18next";

import VideoReviewImg from "../../../../assets/images/video-preview.png";
import { ReactComponent as PlayButton } from "../../../../assets/images/play-button.svg";
import Container from "../../../../shared/wrappers/Container";
import { SectionTitle } from "../../../../shared/components/elements";

const VideoReview = () => {
  const { t } = useTranslation();
  const [glightbox, setGlightbox] = useState(null);

  useEffect(() => {
    if (!glightbox) {
      setGlightbox(
        GLightbox({
          skin: "clean",
          elements: [
            {
              href: "https://www.youtube.com/watch?v=lmJ3_-SZT0o",
              type: "video",
              source: "youtube",
            },
          ],
        })
      );
    }

    return () => {
      glightbox && glightbox.destroy();
    };
  }, [glightbox]);

  const openVideo = () => glightbox && glightbox.open();

  return (
    <Wrapper>
      <Container>
        <Content>
          <VideoHolder>
            <img src={VideoReviewImg} alt="video review" />

            <VideoButton onClick={openVideo}>
              <VideoButtonIcon>
                <PlayButton />
              </VideoButtonIcon>
              <VideoButtonText>{t("Play")}</VideoButtonText>
            </VideoButton>
          </VideoHolder>

          <TextHolder>
            <SectionTitle alone responsive>
              {t("Standarts description")}
            </SectionTitle>
          </TextHolder>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default VideoReview;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.beige};
  padding: 64px 0;
`;

const Content = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 425px) {
    flex-direction: column;
  }
`;

const VideoHolder = styled.div`
  position: relative;
  flex: 1;

  img {
    max-width: 100%;
  }
`;

const VideoButton = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.25s linear;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const VideoButtonIcon = styled.div`
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.orange};
  width: 54px;
  height: 54px;
  padding: 10px;
  border-radius: 50%;
`;

const VideoButtonText = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
`;

const TextHolder = styled.div`
  width: 40%;
  margin-left: 32px;

  @media screen and (max-width: 425px) {
    width: 100%;
    margin-left: 0;
    text-align: center;
    margin-top: 32px;
  }
`;
