import React, { createElement } from "react";
import { transparentize } from "polished";
import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";

const ItemsList = ({ items, linkItems, onClick, current, hideShadow }) => {
  return (
    <List hideShadow={hideShadow}>
      {items.map((item, index) =>
        linkItems ? (
          <LinkItem key={index} to={item.path} activeClassName="current">
            {item.icon && <ItemIcon>{createElement(item.icon)}</ItemIcon>}

            {item.img && (
              <ItemIcon>
                <img src={item.img} alt={item.name || item.label} />
              </ItemIcon>
            )}

            <ItemLabel>{item.name || item.label}</ItemLabel>
          </LinkItem>
        ) : (
          <Item
            key={index}
            onClick={() => onClick(item)}
            isActive={current === (item.value || item.id)}
          >
            {item.icon && <ItemIcon>{createElement(item.icon)}</ItemIcon>}

            {item.img && (
              <ItemIcon>
                <img src={item.img} alt={item.name || item.label} />
              </ItemIcon>
            )}

            <ItemLabel>{item.name || item.label}</ItemLabel>
          </Item>
        )
      )}
    </List>
  );
};

export default ItemsList;

const List = styled.div`
  background-color: ${({ theme }) => transparentize(0.15, theme.colors.white)};
  backdrop-filter: blur(4px);
  padding: 8px 0;
  border-radius: 8px;
  box-shadow: ${({ hideShadow, theme }) =>
    hideShadow
      ? "none"
      : `0 3px 5px 0 ${transparentize(0.9, theme.colors.coal)}`};

  @media screen and (max-width: 425px) {
    box-shadow: ${({ theme }) =>
      `0 3px 5px 0 ${transparentize(0.9, theme.colors.coal)}`};
  }
`;

const ItemStyles = css`
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  color: ${({ theme }) => theme.colors.asphalt};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) =>
      transparentize(0.9, theme.colors.orange)};
  }
`;

const Item = styled.div`
  ${ItemStyles}

  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.orange : theme.colors.asphalt};

  svg * {
    fill: ${({ theme, isActive }) =>
      isActive ? theme.colors.orange : theme.colors.asphalt};
    stroke: ${({ theme, isActive }) =>
      isActive ? theme.colors.orange : theme.colors.asphalt};
  }
`;

const LinkItem = styled(NavLink)`
  ${ItemStyles}

  &.current {
    color: ${({ theme }) => theme.colors.orange};

    svg * {
      fill: ${({ theme }) => theme.colors.orange};
      stroke: ${({ theme }) => theme.colors.orange};
    }
  }
`;

const ItemIcon = styled.div`
  margin-right: 8px;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const ItemLabel = styled.div`
  font-size: 14px;
  white-space: nowrap;
`;
