import { createElement, useEffect } from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import routes from "./routes";
import Header from "./shared/components/Header";
import Footer from "./shared/components/Footer";
import useGetPath from "./shared/hooks/useGetPath";

function App() {
  const { i18n } = useTranslation();
  const getPath = useGetPath();
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const currentLang = pathname.split("/")[1];

    if (i18n.languages.includes(currentLang)) {
      i18n.changeLanguage(currentLang);
    } else if (i18n.language !== "en") {
      history.push(`/${i18n.language}${pathname}`);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Wrapper>
      <Header />

      <Switch>
        {routes.map((route, index) => (
          <Route key={index} path={getPath(route.path)} exact={route.exact}>
            {createElement(route.component)}
          </Route>
        ))}
      </Switch>

      <Footer />
    </Wrapper>
  );
}

export default App;

const Wrapper = styled.div`
  background-color: #fff;
`;
