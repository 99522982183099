import React, { createElement } from "react";
import styled from "styled-components";
import { transparentize } from "polished";
import { useTranslation } from "react-i18next";

import useGetPath from "../../../../shared/hooks/useGetPath";
import { homePageRoutes } from "../../../../routes";
import { Link } from "react-router-dom";

const Sections = () => {
  const { t } = useTranslation();
  const getPath = useGetPath();

  return (
    <Wrapper>
      {homePageRoutes.map((item, index) => (
        <Section key={index} to={getPath(item.path)} bgimg={item.background}>
          <SectionContent>
            <IconHolder>{createElement(item.icon)}</IconHolder>

            <Name>{t(`common:${item.name}`)}</Name>
          </SectionContent>
        </Section>
      ))}
    </Wrapper>
  );
};

export default Sections;

const Wrapper = styled.div`
  display: flex;

  @media screen and (max-width: 425px) {
    flex-direction: column;
  }
`;

const Section = styled(Link)`
  text-decoration: none;
  background-image: url(${({ bgimg }) => bgimg});
  background-size: 105% auto;
  background-position: center;
  width: calc(100% / 4);
  transition-property: background-size;
  transition-duration: 0.3s;
  transition-timing-function: linear;
  display: inline-block;

  &:hover {
    background-size: 100% auto;
  }

  @media screen and (max-width: 425px) {
    margin: 8px auto;
    width: calc(100% - 32px);
  }
`;

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => transparentize(0.5, theme.colors.coal)};
  padding: 200px 0;
  transition: inherit;
  transition-property: background-color, backdrop-filter;

  &:hover {
    background-color: ${({ theme }) => transparentize(0.3, theme.colors.coal)};
    backdrop-filter: blur(3px);
  }
`;

const IconHolder = styled.div``;

const Name = styled.span`
  font-size: 28px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.white};
`;
