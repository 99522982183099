import React from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router";
import { transparentize } from "polished";

import Container from "../../shared/wrappers/Container";
import Info from "./components/Info";
import ImageSlider from "./components/ImageSlider";
import { useGetProduct } from "./useGetProduct";
import { ReactComponent as IconLeftArrow } from "../../assets/images/left-arrow.svg";

const Product = () => {
  const params = useParams();
  const product = useGetProduct(params.productId);
  const history = useHistory();

  return (
    <Wrapper>
      <GoBack onClick={() => history.goBack()}>
        <IconLeftArrow />
      </GoBack>

      <Container>
        {product && (
          <Content>
            <SliderHolder>
              {product.images?.length && (
                <ImageSlider
                  images={product.images}
                  imagesWeb={product.imagesWeb}
                  imagesMobile={product.imagesMobile}
                  url3D={product.url3D}
                />
              )}
            </SliderHolder>

            <InfoHolder>
              <Info product={product} />
            </InfoHolder>
          </Content>
        )}
      </Container>
    </Wrapper>
  );
};

export default Product;

const Wrapper = styled.div`
  padding-top: 96px;
  padding-bottom: 24px;
  background-color: ${({ theme }) => theme.colors.beige};
  position: relative;

  @media screen and (max-width: 425px) {
    padding-top: 64px;
  }
`;

const GoBack = styled.div`
  position: fixed;
  top: 72px;
  left: 16px;
  z-index: 2;
  width: 30px;
  height: 30px;
  background-color: ${({ theme }) => transparentize(0.25, theme.colors.beige)};
  backdrop-filter: blur(4px);
  border-radius: 4px;
  display: none;

  @media screen and (max-width: 425px) {
    display: block;
  }

  svg {
    max-width: 100%;
    max-height: 100%;
    fill: ${({ theme }) => theme.colors.orange};
  }
`;

const Content = styled.div`
  display: flex;

  @media screen and (max-width: 425px) {
    flex-direction: column;
  }
`;

const SliderHolder = styled.div`
  flex: 1;
  max-width: calc(100% - 33.3% - 32px);

  @media screen and (max-width: 425px) {
    max-width: 100%;
  }
`;

const InfoHolder = styled.div`
  margin-left: 32px;
  width: 33.3%;

  @media screen and (max-width: 425px) {
    width: 100%;
    margin-left: 0;
    margin-top: 24px;
  }
`;
