import React, { useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { useClickAway } from "react-use";

import ItemsList from "../../../../shared/components/ItemsList";
import { ReactComponent as IconSewingDark } from "../../../../assets/images/icon-sewing-dark.svg";

const Categories = ({ data, currentId }) => {
  const [visible, setVisibility] = useState(false);
  const listRef = useRef(null);

  const current = useMemo(
    () => data.find((item) => item.id === currentId),
    [data, currentId]
  );

  useClickAway(listRef, () => {
    setVisibility(false);
  });

  return (
    <Wrapper ref={listRef}>
      <Current onClick={() => setVisibility(!visible)} visible={visible}>
        <CurrentIcon>
          <IconSewingDark />
        </CurrentIcon>
        <CurrentLabel>{current?.label}</CurrentLabel>
      </Current>

      <ListHolder visible={visible}>
        <ItemsList items={data} current={current?.id} hideShadow linkItems />
      </ListHolder>
    </Wrapper>
  );
};

export default Categories;

const Wrapper = styled.div`
  position: relative;
`;

const Current = styled.div`
  display: none;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 8px;
  border-radius: 8px;

  @media screen and (max-width: 425px) {
    display: flex;
  }
`;

const CurrentIcon = styled.div`
  margin-right: 8px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg * {
    fill: ${({ theme }) => theme.colors.orange};
    stroke: ${({ theme }) => theme.colors.orange};
  }
`;

const CurrentLabel = styled.div`
  font-size: 14px;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.asphalt};
`;

const ListHolder = styled.div`
  display: block;
  position: static;
  transition: all 0.25s linear;

  @media screen and (max-width: 425px) {
    position: absolute;
    left: 0;
    top: calc(100% + 4px);
    z-index: 1;
    transform: translateY(${({ visible }) => (visible ? 0 : "-100%")});
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  }
`;
