import React, { useRef } from "react";
import styled from "styled-components";

import MainSlider from "./MainSlider";
import NavSlider from "./NavSlider";

const ImageSlider = ({ images, imagesWeb, imagesMobile, url3D }) => {
  const navSliderRef = useRef();
  const mainSliderRef = useRef();

  return (
    <Wrapper>
      <NavSliderHolder>
        <NavSlider
          images={imagesMobile}
          selfRef={navSliderRef}
          asNavForRef={mainSliderRef}
          url3D={url3D}
        />
      </NavSliderHolder>

      <MainSliderHolder>
        <MainSlider
          images={images}
          selfRef={mainSliderRef}
          asNavForRef={navSliderRef}
          url3D={url3D}
        />
      </MainSliderHolder>
    </Wrapper>
  );
};

export default ImageSlider;

const Wrapper = styled.div`
  display: flex;

  @media screen and (max-width: 425px) {
    flex-direction: column;
  }
`;

const NavSliderHolder = styled.div`
  margin: -8px 0;
  overflow: hidden;
  width: 132px;

  @media screen and (max-width: 425px) {
    order: 2;
    margin: 0;
    width: 100%;
  }
`;

const MainSliderHolder = styled.div`
  margin-left: 32px;
  width: calc(100% - 182px);

  @media screen and (max-width: 425px) {
    order: 1;
    margin-left: 0;
    width: 100%;
  }
`;
