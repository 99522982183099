import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  SectionText,
  SectionTitle,
} from "../../../../shared/components/elements";
import Button from "../../../../shared/components/Button";
import useGetPath from "../../../../shared/hooks/useGetPath";

const Info = () => {
  const { t } = useTranslation();
  const getPath = useGetPath();

  return (
    <TextContent>
      <SectionTitle>{t("Who are we?")}</SectionTitle>

      <SectionText>{t("About description")}</SectionText>

      <Link to={getPath("/cooperation")}>
        <Button outlined>{t("About cooperation")}</Button>
      </Link>
    </TextContent>
  );
};

export default Info;

const TextContent = styled.div`
  width: 40%;
  margin: 64px 0;

  @media screen and (max-width: 425px) {
    width: 100%;
  }
`;
