import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import InfoContent from "../../../../shared/components/InfoContent";
import ProductionImg2 from "../../../../assets/images/production-img-2.png";
import ProductionImg3 from "../../../../assets/images/production-img-3.png";
import ProductionImg4 from "../../../../assets/images/production-img-4.png";

const Info = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <InfoContent
        title={t("Our equipment")}
        text={t("ourEquipmentText")}
        image={ProductionImg2}
      />

      <InfoContent
        title={t("Quality material")}
        text={t("qualityMaterialText")}
        image={ProductionImg3}
        reversed
      />

      <InfoContent
        title={t("Professional approach")}
        text={
          <>
            <p>{t("professionalApproachText1")}</p>

            <br />

            <p>{t("professionalApproachText2")}</p>
          </>
        }
        image={ProductionImg4}
      />
    </Wrapper>
  );
};

export default Info;

const Wrapper = styled.div`
  padding: 48px 0;
`;
