import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import WelcomBg from "../../../../assets/images/welcome.svg";
import Container from "../../../../shared/wrappers/Container";
import {
  LeadText,
  LeadTextAccent,
  SectionText,
} from "../../../../shared/components/elements";
import Button from "../../../../shared/components/Button";

const Welcome = () => {
  const { t } = useTranslation();
  const [moreText, setMoreText] = useState(false);

  return (
    <Wrapper>
      <CustomContainer>
        <TextContent>
          <LeadText>
            {t("We have been producing knitwear for")}

            <br />

            <LeadTextAccent>{t("more than 10 years")}</LeadTextAccent>
          </LeadText>

          <SectionText>{t("Welcome Text")}</SectionText>

          <Button
            onClick={() => {
              console.log("object");
              setMoreText(!moreText);
            }}
          >
            {t("common:Learn more")}
          </Button>

          {moreText && (
            <SectionText>
              <br />
              <p>{t("learnMoreText1")}</p>
              <br />
              <b><p>{t("learnMoreText2")}</p></b>
              <br />
              <p>{t("learnMoreText2 p1")}</p>
              <p>{t("learnMoreText2 p2")}</p>
              <p>{t("learnMoreText2 p3")}</p>
            </SectionText>
          )}
        </TextContent>
      </CustomContainer>
    </Wrapper>
  );
};

export default Welcome;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.beige};
`;

const CustomContainer = styled(Container)`
  background-image: url("${WelcomBg}");
  background-position: right 80px;
  background-repeat: no-repeat;
  background-size: calc(100% + 36px) auto;

  @media screen and (max-width: 425px) {
    background-size: 200% auto;
    background-position-y: 8vh;
  }
`;

const TextContent = styled.div`
  width: 35%;
  padding-top: 300px;
  padding-bottom: 100px;

  @media screen and (max-width: 425px) {
    width: 100%;
    padding-top: 94vw;
    padding-bottom: 64px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`;
