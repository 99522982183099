const theme = {
  colors: {
    asphalt: "#4A5567",
    beige: "#FFF8F5",
    black: "#000",
    coal: "#1A202C",
    grey: "#E2E8F0",
    orange: "#D14E40",
    purple: "#A4C0F8",
    rose: "#FDB3B3",
    white: "#fff",
    yellow: "#FAD292",
  },
};

export default theme;
