import React from "react";
import styled from "styled-components";
import { darken, lighten } from "polished";
import { useTranslation } from "react-i18next";
import Container from "../../../../shared/wrappers/Container";
import {
  SectionTitle,
  SectionText,
} from "../../../../shared/components/elements";
import { ReactComponent as IconShirt } from "../../../../assets/images/icon-shirt.svg";
import { ReactComponent as IconClothes } from "../../../../assets/images/icon-clothes.svg";
import { ReactComponent as IconSewing } from "../../../../assets/images/icon-sewing.svg";
import { ReactComponent as IconNeedle } from "../../../../assets/images/icon-needle.svg";

const Guarantee = () => {
  const { t } = useTranslation();

  const items = [
    {
      icon: <IconShirt />,
      name: t("Excellent quality"),
      description: t("Excellent quality description p1"),
      description1: t("Excellent quality description p2"),
      description2: t("Excellent quality description p3"),
    },
    {
      icon: <IconClothes />,
      name: t("Any quantity"),
      description: t("Any quantity description"),
      description1: t("empty"),
      description2: t("empty"),
    },
    {
      icon: <IconSewing />,
      name: t("Short lead times"),
      description: t("Short lead times description"),
      description1: t("empty"),
      description2: t("empty"),
    },
    {
      icon: <IconNeedle />,
      name: t("Customer Support"),
      description: t("Customer Support description p1"),
      description1: t("Customer Support description p2"),
      description2: t("empty"),
    },
  ];

  return (
    <Wrapper>
      <Container>
        <SectionTitle>{t("We guarantee")}</SectionTitle>

        <GuaranteeItems>
          {items.map((item, index) => (
            <Item key={index}>
              <ItemHeader>
                <Icon>{item.icon}</Icon>

                <ItemTitle>{item.name}</ItemTitle>
              </ItemHeader>

              <SectionText alone>{item.description}</SectionText>
              <SectionText alone>{item.description1}</SectionText>
              <SectionText alone>{item.description2}</SectionText>
            </Item>
          ))}
        </GuaranteeItems>
      </Container>
    </Wrapper>
  );
};

export default Guarantee;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.beige};
  padding: 64px 0;
`;

const GuaranteeItems = styled.div`
  display: flex;

  @media screen and (max-width: 425px) {
    flex-direction: column;
  }
`;

const Item = styled.div`
  flex: 1;

  &:not(:last-child) {
    margin-right: 32px;
  }
`;

const ItemHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  @media screen and (max-width: 425px) {
    flex-direction: row;
    align-items: center;
  }
`;

const Icon = styled.div`
  background: linear-gradient(153.02deg, #eb3349 1.39%, #f45c43 100%);
  background: ${({ theme }) => `linear-gradient(
    135deg, 
    ${darken(0.025, theme.colors.orange)} 0px, 
    ${lighten(0.1, theme.colors.orange)} 100%
  )`};
  margin-right: 16px;
  width: 110px;
  height: 110px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 425px) {
    width: 56px;
    height: 56px;

    svg {
      max-width: 50%;
    }
  }
`;

const ItemTitle = styled.div`
  color: ${({ theme }) => theme.colors.coal};
  font-size: 18px;
  font-weight: 700;
  margin-top: 24px;

  @media screen and (max-width: 425px) {
    margin-top: 0;
  }
`;
