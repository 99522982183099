import React, { createElement } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  SectionTitle,
  SectionText,
} from "../../../../shared/components/elements";
import Container from "../../../../shared/wrappers/Container";
import useStaticData from "./useStaticData.js";

const Advantages = () => {
  const { t } = useTranslation();
  const advantages = useStaticData();

  return (
    <Wrapper>
      <Container>
        <SectionTitle>{t("The advantages of our company")}</SectionTitle>

        <CardList>
          {advantages.map((advantage, index) => (
            <Card key={index}>
              <CardIcon>{createElement(advantage.icon)}</CardIcon>

              <CardTitle>{advantage.title}</CardTitle>

              <SectionText alone>{advantage.text}</SectionText>
            </Card>
          ))}
        </CardList>
      </Container>
    </Wrapper>
  );
};

export default Advantages;

const Wrapper = styled.div``;

const CardList = styled.div`
  margin: 0 -16px;
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 425px) {
    flex-direction: column;
    margin: 0;
  }
`;

const Card = styled.div`
  margin: 16px;
  padding: 48px 32px;
  background-color: ${({ theme }) => theme.colors.beige};
  width: calc(100% / 3 - 32px);

  @media screen and (max-width: 425px) {
    width: 100%;
    margin: 8px 0;
  }
`;

const CardIcon = styled.div`
  margin-bottom: 24px;
`;

const CardTitle = styled.div`
  font-size: 28px;
  line-height: 1.25;
  margin-bottom: 16px;
`;
