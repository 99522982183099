import React, { createElement, useState } from "react";
import styled from "styled-components";

const Accordeon = ({ icon, name, children }) => {
  const [visible, setVisibility] = useState(false);

  return (
    <Wrapper>
      <Header onClick={() => setVisibility(!visible)}>
        {icon && <Icon>{createElement(icon)}</Icon>}
        <Name>{name}</Name>
        <Button>{visible ? "-" : "+"}</Button>
      </Header>

      {visible && <Body>{children}</Body>}
    </Wrapper>
  );
};

export default Accordeon;

const Wrapper = styled.div`
  margin: 16px 0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.colors.orange};
  cursor: pointer;
`;

const Icon = styled.div`
  width: 32px;
  height: 32px;
  font-size: 32px;
  margin-right: 24px;

  svg {
    max-width: 100%;
    max-height: 100%;
  }
`;

const Name = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.coal};
  margin-right: 24px;
  flex: 1;
`;

const Button = styled.div`
  width: 24px;
  height: 24px;
  border: 1px solid ${({ theme }) => theme.colors.coal};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.orange};
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 0;
  padding-top: 1px;
  padding-bottom: 3px;
`;

const Body = styled.div`
  margin: 16px 0 24px;
  color: ${({ theme }) => theme.colors.asphalt};
`;
