import React from "react";

import Welcome from "./components/Welcome";
import Info from "./components/Info";

const Production = () => {
  return (
    <div>
      <Welcome />
      <Info />
    </div>
  );
};

export default Production;
